import * as React from "react"
import { Grid, Paper, Typography } from "@mui/material"
import { config } from "@/config"
import { Link } from "@/lib/routing"
import { BaseLayout } from "@/views/_layout"
import { Box } from "@mui/material"
import { useSession } from "@/domain/auth"
import { useRouter } from "next/router"

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ py: 4 }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        {config.appName}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export function AuthLayout({
  children,
  title,
}: {
  children: React.ReactNode
  title?: string
}) {
  const session = useSession()
  const router = useRouter()

  if (session.type === "authenticated") {
    router.replace("/dashboard")
    return null
  }

  return (
    <BaseLayout title={title}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={3}
          md={6}
          sx={{
            backgroundImage: "url(/background2.svg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{ display: "flex", flexDirection: "column" }}
          variant="elevation"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={4}
          >
            {/* eslint-disable-next-line */}
            <img
              src="/logo-color.png"
              alt="Life Experience Exchange logo"
              width="180"
            />
          </Box>
          <Box flexGrow={1}>{children}</Box>
          <Copyright />
        </Grid>
      </Grid>
    </BaseLayout>
  )
}
