import { useEffect } from "react"
import {
  // Checkbox,
  Box,
  Button,
  Container,
  // FormControlLabel,
  Stack,
  TextField,
  Typography,
  Alert,
} from "@mui/material"
import { NextLinkComposed } from "@/lib/routing"
import { AuthLayout } from "@/views/auth/_layout"
import { useForm } from "react-hook-form"
import { LoadingButton } from "@mui/lab"
import { useRouter } from "next/router"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { UserNotVerifiedModal } from "./components/user-not-verified-modal"
import { useLogin } from "@/domain/auth"
import { isAxiosError } from "@/lib/error"
import { useBooleanState } from "@/lib/hooks"

const FormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Vyplňte e-mailovou adresu" })
    .email({ message: "Vyplňte platnou e-mailovou adresu" }),
  password: z.string().min(1, { message: "Vyplňte heslo" }),
})

type FailureReason = "wrong-credentials" | "not-verified" | "other"

function getFailureReason(error: unknown): FailureReason {
  if (isAxiosError(error) && error.response) {
    const { status } = error.response

    if (status === 403) {
      return "not-verified"
    }

    if (status === 404) {
      return "wrong-credentials"
    }

    return "other"
  }

  return "other"
}

export default function LogIn() {
  const title = "Přihlášení"

  const router = useRouter()
  const { email, verified } = router.query
  const isVerified = verified !== undefined

  const loginMutation = useLogin()
  const failureReason = getFailureReason(loginMutation.error)

  const notVerifiedDialog = useBooleanState()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
  })

  useEffect(() => {
    if (typeof email === "string") {
      setValue("email", email)
    }
  }, [email])

  useEffect(() => {
    if (failureReason === "not-verified") {
      notVerifiedDialog.on()
    }
  }, [failureReason])

  const onSubmit = (data: any) => {
    loginMutation.mutate({
      username: data.email,
      password: data.password,
    })
  }

  return (
    <AuthLayout title={title}>
      <Container maxWidth="sm">
        <Box sx={{ mt: 6, mb: 4 }}>
          <Typography component="h1" variant="h4" fontWeight={700}>
            {title}
          </Typography>
        </Box>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 1 }}
        >
          <Stack spacing={3}>
            {isVerified && (
              <Alert severity="success">
                Váš email byl úspešně oveřen. Nyní se můžete přihlásit
              </Alert>
            )}
            {failureReason === "wrong-credentials" && (
              <Alert severity="error">Nesprávné přihlašovací údaje</Alert>
            )}
            <TextField
              {...register("email")}
              required
              fullWidth
              id="email"
              label="E-mail"
              autoComplete="email"
              type="email"
              autoFocus
              variant="filled"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
            <Stack spacing={1}>
              <TextField
                {...register("password")}
                required
                fullWidth
                label="Heslo"
                type="password"
                id="password"
                autoComplete="current-password"
                variant="filled"
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
              />
              {/* <Box>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Zapamatovat si přihlášení"
                />
              </Box> */}
            </Stack>
            <Stack
              justifyContent="space-between"
              spacing={2}
              direction={{ xs: "column", lg: "row" }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loginMutation.isLoading}
              >
                Přihlásit se
              </LoadingButton>
              <Stack spacing={1} direction={{ xs: "column", lg: "row" }}>
                <Button
                  variant="text"
                  component={NextLinkComposed}
                  to="/reset-password"
                >
                  Zapomněli jste heslo?
                </Button>
                <Button
                  variant="text"
                  component={NextLinkComposed}
                  to="/signup"
                >
                  {"Nemáte účet?"}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Container>
      <UserNotVerifiedModal
        open={notVerifiedDialog.value}
        onClose={notVerifiedDialog.off}
      />
    </AuthLayout>
  )
}
