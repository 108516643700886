import React from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Stack,
} from "@mui/material"
import { DraftsRounded } from "@mui/icons-material"

interface UserNotVerifiedModalProps {
  open: boolean
  onClose: () => void
}

export function UserNotVerifiedModal({
  open,
  onClose,
}: UserNotVerifiedModalProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Stack
          sx={{ textAlign: "center", alignItems: "center", my: 6 }}
          spacing={1}
        >
          <DraftsRounded sx={{ fontSize: 80, color: "primary.light", mb: 2 }} />
          <Typography variant="h5" fontWeight={700}>
            Váš e-mail ještě není ověřený
          </Typography>
          <Typography>
            Pro oveření klikněte na odkaz v mailu, který jsme vám poslali při
            registraci.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Zavřít</Button>
      </DialogActions>
    </Dialog>
  )
}
